import { AriaCheckboxGroupItemProps, useCheckboxGroupItem } from "react-aria";
import React from "react";
import { StyledCheckbox, StyledLabel } from "atom/checkbox/checkboxComponents";
import { CheckboxGroupContext } from "atom/checkbox/CheckboxGroupContext";

function Checkbox(props: AriaCheckboxGroupItemProps) {
  const { children } = props;
  const state = React.useContext(CheckboxGroupContext);
  const ref = React.useRef(null);
  const { inputProps } = useCheckboxGroupItem(props, state, ref);

  const isDisabled = state.isDisabled || props.isDisabled;
  const isSelected = state.isSelected(props.value);

  return (
    <StyledLabel data-disabled={isDisabled} data-selected={isSelected}>
      <StyledCheckbox {...inputProps} ref={ref} />
      {children}
    </StyledLabel>
  );
}

export default Checkbox;
